
import Historico from "@/components/profesional/ProfesionalHistorico.vue";
import ServiciosTabla from "@/components/servicios/TablaFamilias.vue";
import comarcasResidencia from "@/core/data/comarcas";
import { nivel, nombreIdioma } from "@/core/data/idiomas";
import { dias, horarios } from "@/core/data/tiemposProfesionales";
import { sinTitulaciones, titulaciones } from "@/core/data/titulaciones";
import tratamientos from "@/core/data/tratamientos";
import vehiculos from "@/core/data/vehiculos";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Page from "@/layout/Page.vue";
import {
  AuthModule,
  OrganismosModule,
  ProfesionalesListadoModule,
} from "@/store/modules";
import { Documento, Experiencia, IAMFile, Idioma, Tiempo } from "@/types";
import ServiciosColectivosTabla from "@/views/profesionales/ServiciosColectivos.vue";
import { ArrowDown } from "@element-plus/icons-vue";
import imageCompression from "browser-image-compression";
import Dropzone from "dropzone";
import { ElMessage, ElNotification } from "element-plus";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, useField, useForm } from "vee-validate";
import { computed, defineComponent, onBeforeMount, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";
import * as Yup from "yup";

interface Profesional {
  tratamiento: string;
  nombre: string;
  apellidos: string;
  dni: string;
  email: string;
  telefono: string;
  fechaNacimiento: Date;
  direccion: string;
  provincia: string;
  localidad: string;
  carnetConducir: boolean;
  vehiculo?: string;
  disponeTitulacion: boolean;
  experienciaCuidadoMenores: string;
  laborables: boolean;
  finesDeSemana: boolean;
  festivos: boolean;
  urgencias: boolean;
  noches: boolean;
  detalleDisponibilidad?: string;
  comarcaId: string;
  comarcasTrabajoIds?: string[];
  comarcasSolicitaTrabajoIds?: string[];
  idiomas?: Idioma[];
  experiencia?: Experiencia[];
  titulacion?: string[];
  titulacionOtras?: string;
  documentosEliminar?: string[];
  documentosNuevos?: IAMFile[];
  disponibilidadesArray?: Tiempo[];
}

export default defineComponent({
  name: "formularios-profesional-detalle",
  components: {
    ErrorMessage,
    Page,
    ServiciosTabla,
    ServiciosColectivosTabla,
    ArrowDown,
    Historico,
  },

  methods: {
    addIdioma() {
      if (!this.idiomas) {
        this.idiomas = [];
      }
      this.idiomas.push({ nombreIdioma: "", nivel: "" });
    },
    removeIdioma(index) {
      if (this.idiomas.length > 1) {
        this.idiomas.splice(index, 1);
      } else {
        this.idiomas = [{ nombreIdioma: "", nivel: "" }];
      }
    },
    addExperiencia() {
      if (!this.experiencia) {
        this.experiencia = [];
      }
      this.experiencia.push({
        tipo: "",
        dirigidoA: "",
        duracion: "",
      });
    },
    removeExperiencia(index) {
      if (this.experiencia.length > 1) {
        this.experiencia.splice(index, 1);
      } else {
        this.experiencia = [{ tipo: "", dirigidoA: "", duracion: "" }];
      }
    },

    removeDocumento(index) {
      let documentoId = this.documentos[index].id as string;
      if (!this.documentosEliminar) {
        this.documentosEliminar = [];
      }
      this.documentosEliminar.push(documentoId);
      this.documentos.splice(index, 1);
    },

    includeDocumentoNuevo(file) {
      const reader = new FileReader();
      return new Promise<void>((resolve, reject) => {
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.documentosNuevos.push({
            nombre: file.name,
            data: reader.result?.toString() || "",
          });
          if (Object.keys(this.erroresDocumentos).length === 0) {
            this.isReading = false;
          } else {
            this.isReading = true;
          }
          resolve();
        };
        reader.onerror = () => {
          this.erroresDocumentos.value[file.name] = true;
          this.isReading = true;
          reject();
        };
      });
    },
    deleteDocumentoNuevo(file) {
      this.documentosNuevos = this.documentosNuevos.filter(function (item) {
        return item.nombre !== file.name;
      });

      if (Object.keys(this.erroresDocumentos).length === 0) {
        this.isReading = false;
      }
    },
    compressImage(file) {
      const compressionOptions = { maxSizeMB: 0.3 };
      return imageCompression(file, compressionOptions);
    },
    addHorario() {
      if (!this.disponibilidadesArray) {
        this.disponibilidadesArray = [];
      }
      this.disponibilidadesArray.push({
        dias: [],
        horas: [],
      });
    },
    removeHorario(index) {
      if (this.disponibilidadesArray.length > 1) {
        this.disponibilidadesArray.splice(index, 1);
      } else {
        this.disponibilidadesArray = [{ dias: [], horas: [] }];
      }
    },

    changeDisponeTitulacion() {
      this.titulacion = [];
    },
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const token = route.params.id;

    const profesional = ref();

    const store = useStore();
    const module = getModule(ProfesionalesListadoModule, store);
    const authModule = getModule(AuthModule, store);
    const moduleOrganismos = getModule(OrganismosModule, store);
    const comarcas = computed(() => moduleOrganismos.organismosListado);
    const comarcaId = authModule.user.comarcaId;
    const error = computed(() => module.errors);

    const userComarcaId = authModule.user.comarcaId;

    watch(error, () => {
      if (!error.value) return;

      Swal.fire({
        text:
          t("errors." + (error.value.error.code ?? error.value.error.name)) ??
          error.value.error.name,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t("dialogs.okButton"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    });

    const disabledDate = (time: Date) => {
      return time.getTime() > Date.now();
    };

    const cargaDetalle = ref<boolean>(false);
    const guardaDetalle = ref<boolean>(false);
    const estado = ref<string>("");
    const claseEstado = ref<string>("");
    onBeforeMount(async () => {
      cargaDetalle.value = true;
      try {
        await module.fetchById({ comarcaId: comarcaId, id: token as string });
        await module.fetchProfesionalActualizarDatosById({
          comarcaId: comarcaId as string,
          id: token as string,
        });
        await moduleOrganismos.fetchAll();
      } catch (err) {
        Swal.fire({
          text: t("formularios.profesionalDetalle.cargar.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } finally {
        cargaDetalle.value = false;
      }
    });

    const datosProfesional = computed(() => module.profesional);
    watch(datosProfesional, () => {
      if (!datosProfesional.value) return;

      estado.value = datosProfesional.value?.estado.replace("_", " ");
      switch (estado.value.replace(" ", "_")) {
        case "PENDIENTE_DOCUMENTACION":
          claseEstado.value = "badge badge-warning";
          break;
        case "APTO":
          claseEstado.value = "badge badge-success";
          break;
        case "SUSPENDIDO":
          claseEstado.value = "badge badge-danger";
          break;
        default:
          claseEstado.value = "d-none";
          break;
      }

      resetForm({
        values: {
          tratamiento: datosProfesional.value.tratamiento,
          nombre: datosProfesional.value.nombre,
          apellidos: datosProfesional.value.apellidos,
          dni: datosProfesional.value.dni,
          email: datosProfesional.value.email,
          telefono: datosProfesional.value.telefono,
          fechaNacimiento: datosProfesional.value.fechaNacimiento,
          direccion: datosProfesional.value.direccion,
          provincia: datosProfesional.value.provincia,
          localidad: datosProfesional.value.localidad,
          comarcaId: datosProfesional.value.comarcaId,
          carnetConducir: datosProfesional.value.carnetConducir,
          vehiculo: datosProfesional.value.vehiculo,
          disponeTitulacion: datosProfesional.value.disponeTitulacion,
          experienciaCuidadoMenores:
            datosProfesional.value.experienciaCuidadoMenores,
          laborables: datosProfesional.value.laborables,
          finesDeSemana: datosProfesional.value.finesDeSemana,
          festivos: datosProfesional.value.festivos,
          urgencias: datosProfesional.value.urgencias,
          noches: datosProfesional.value.noches,
          detalleDisponibilidad: datosProfesional.value.detalleDisponibilidad,
          comarcasTrabajoIds: datosProfesional.value.comarcasTrabajoIds,
          comarcasSolicitaTrabajoIds:
            datosProfesional.value.comarcasSolicitaTrabajoIds,
          idiomas: datosProfesional.value.idiomas,
          experiencia: datosProfesional.value.experiencia,
          titulacion: datosProfesional.value.titulacion,
          titulacionOtras: datosProfesional.value.titulacionOtras || "",
          documentosEliminar: datosProfesional.value.documentosEliminar || [],
          documentosNuevos: datosProfesional.value.documentosNuevos || [],
          disponibilidadesArray: datosProfesional.value.disponibilidadesArray,
        },
      });
      documentos.value = datosProfesional.value.documentos || [];
    });

    watch(datosProfesional, () => {
      setCurrentPageBreadcrumbs(datosProfesional.value?.dni ?? "", [
        t("menu.personas"),
        { title: t("menu.profesionales"), path: "/listados/profesionales" },
      ]);
    });

    const schema = Yup.object({
      tratamiento: Yup.string()
        .required()
        .label(t("formularios.profesionalDetalle.tratamiento.label")),
      nombre: Yup.string()
        .required()
        .label(t("formularios.profesionalDetalle.nombre.label")),
      apellidos: Yup.string()
        .required()
        .label(t("formularios.profesionalDetalle.apellidos.label")),
      dni: Yup.string()
        .required()
        .label(t("formularios.profesionalDetalle.dni.label")),
      email: Yup.string()
        .required()
        .email()
        .matches(
          /^(")?(?:[^."])(?:(?:[.])?(?:[\w\-!#$%&'*+/=?^_`{|}~]))*\1@(\w[-\w]*\.){1,5}([A-Za-z]){2,9}$/,
          t("formularios.profesionalDetalle.emailValido.label")
        )
        .label(t("formularios.profesionalDetalle.email.label")),
      telefono: Yup.string()
        .required()
        .label(t("formularios.profesionalDetalle.telefono.label")),
      fechaNacimiento: Yup.date()
        .required()
        .label(t("formularios.profesionalDetalle.fechaNacimiento.label")),
      direccion: Yup.string()
        .required()
        .label(t("formularios.profesionalDetalle.direccion.label")),
      provincia: Yup.string()
        .required()
        .label(t("formularios.profesionalDetalle.provincia.label")),
      localidad: Yup.string()
        .required()
        .label(t("formularios.profesionalDetalle.localidad.label")),
      comarcaId: Yup.string()
        .required()
        .label(t("formularios.profesionalDetalle.comarca.label")),
      carnetConducir: Yup.bool()
        .required()
        .label(t("formularios.profesionalDetalle.carnetConducir.label")),
      vehiculo: Yup.string().when("carnetConducir", {
        is: true,
        then: Yup.string()
          .required()
          .label(t("formularios.profesionalDetalle.vehiculoPropio.label")),
        otherwise: Yup.string().nullable(),
      }),
      disponeTitulacion: Yup.bool()
        .required()
        .label(t("formularios.profesionalDetalle.disponeTitulacion.label")),
      experienciaCuidadoMenores: Yup.string()
        .required()
        .label(
          t("formularios.profesionalDetalle.experienciaCuidadoMenores.label")
        ),
      laborables: Yup.bool().required(),
      finesDeSemana: Yup.bool().required(),
      festivos: Yup.bool().required(),
      urgencias: Yup.bool().required(),
      noches: Yup.bool().required(),
      detalleDisponibilidad: Yup.string(),
      comarcasTrabajoIds: Yup.array()
        .required()
        .label(t("formularios.profesionalDetalle.comarcasTrabajo.label")),
      comarcasSolicitaTrabajoIds: Yup.array()
        .required()
        .label(
          t("formularios.profesionalDetalle.comarcasSolicitaTrabajo.label")
        ),
      idiomas: Yup.array()
        .required()
        .label(t("formularios.profesionalDetalle.idiomas.label")),
      experiencia: Yup.array().when("experienciaCuidadoMenores", {
        is: (experienciaCuidadoMenores) =>
          //experienciaCuidadoMenores !== "SINEXPERIENCIA",
          experienciaCuidadoMenores == "SNA_SIEMPRE_OPCIONAL",
        then: Yup.array()
          .of(
            Yup.object({
              tipo: Yup.string()
                .required()
                .label(
                  t("formularios.profesionalDetalle.experiencia.tipo.label")
                ),
              dirigidoA: Yup.string()
                .required()
                .label(
                  t(
                    "formularios.profesionalDetalle.experiencia.dirigidoA.label"
                  )
                ),
              duracion: Yup.string()
                .required()
                .label(
                  t("formularios.profesionalDetalle.experiencia.duracion.label")
                ),
            })
          )
          .ensure()
          .min(1),
        otherwise: Yup.array(),
      }),
      titulacion: Yup.array()
        .required()
        .label(t("formularios.profesionalDetalle.titulacion.label")),
      titulacionOtras: Yup.string().when("titulacion", {
        is: (titulacion) => titulacion?.includes("OTRAS"),
        then: Yup.string()
          .required()
          .label(t("formularios.profesionalDetalle.titulacionOtras.label")),
        otherwise: Yup.string().nullable(),
      }),
      documentos: Yup.array(),
      documentosEliminar: Yup.array(),
      documentosNuevos: Yup.array(),
      disponibilidadesArray: Yup.array(),
    });

    const { resetForm, handleSubmit } = useForm<Profesional>({
      validationSchema: schema,
    });

    const { value: tratamiento } = useField("tratamiento");
    const { value: nombre } = useField("nombre");
    const { value: apellidos } = useField("apellidos");
    const { value: dni } = useField("dni");
    const { value: email } = useField("email");
    const { value: telefono } = useField("telefono");
    const { value: fechaNacimiento } = useField("fechaNacimiento");
    const { value: direccion } = useField("direccion");
    const { value: provincia } = useField("provincia");
    const { value: localidad } = useField("localidad");
    const { value: comarcaIdV } = useField("comarcaId");
    const { value: carnetConducir } = useField("carnetConducir");
    const { value: vehiculo, resetField: vehiculoPropioReset } =
      useField("vehiculo");
    const { value: disponeTitulacion } = useField("disponeTitulacion");
    const { value: experienciaCuidadoMenores } = useField(
      "experienciaCuidadoMenores"
    );
    const { value: laborables } = useField("laborables");
    const { value: finesDeSemana } = useField("finesDeSemana");
    const { value: festivos } = useField("festivos");
    const { value: urgencias } = useField("urgencias");
    const { value: noches } = useField("noches");
    const { value: detalleDisponibilidad } = useField("detalleDisponibilidad");
    const { value: comarcasTrabajoIds } = useField("comarcasTrabajoIds");
    const { value: comarcasSolicitaTrabajoIds } = useField(
      "comarcasSolicitaTrabajoIds"
    );
    const { value: idiomas } = useField<Array<Idioma>>("idiomas");
    const { value: experiencia } = useField<Array<Experiencia>>("experiencia");
    const { value: titulacion } = useField<string[]>("titulacion");
    const { value: titulacionOtras } = useField("titulacionOtras");
    const { value: disponibilidadesArray } = useField<Array<Tiempo>>(
      "disponibilidadesArray"
    );
    const documentos = ref<Documento[]>([]);
    const { value: documentosEliminar } =
      useField<string[]>("documentosEliminar");
    const { value: documentosNuevos } = useField<IAMFile[]>("documentosNuevos");

    const showOtrasTitulaciones = computed(() => {
      return true;
      // SNA: Siempre muestra la opción de "OTRAS titulaciones".
      //return titulacion.value?.includes("OTRAS");
    });

    watch(carnetConducir, () => {
      if (carnetConducir.value === false) {
        vehiculoPropioReset();
      }
    });

    const servicios = computed(() => {
      return (
        datosProfesional.value?.servicios?.filter(
          (servicio) => servicio.servicioProgramadoId === null
        ) ?? []
      );
    });

    const serviciosColectivos = computed(() => {
      return (
        datosProfesional.value?.servicios?.filter(
          (servicio) => servicio.servicioProgramadoId !== null
        ) ?? []
      );
    });

    const seSolicitaActualizacion = ref<boolean>(false);

    const datosProfesionalActualizar = computed(
      () => module.profesionalActualizarDatos
    );
    watch(datosProfesionalActualizar, () => {
      if (datosProfesionalActualizar.value) {
        seSolicitaActualizacion.value = true;
      }
    });

    const isReading = ref<boolean>(false);
    let erroresDocumentos = ref<{ [key: string]: boolean }>({});

    return {
      datosProfesionalActualizar,
      cargaDetalle,
      guardaDetalle,
      profesional,
      datosProfesional,
      tratamientos,
      tratamiento,
      nombre,
      apellidos,
      dni,
      email,
      telefono,
      fechaNacimiento,
      direccion,
      provincia,
      localidad,
      comarcaId: comarcaIdV,
      carnetConducir,
      vehiculo,
      disponeTitulacion,
      experienciaCuidadoMenores,
      laborables,
      finesDeSemana,
      festivos,
      urgencias,
      noches,
      detalleDisponibilidad,
      nombreIdioma,
      nivel,
      comarcas,
      comarcasResidencia,
      titulaciones,
      sinTitulaciones,
      dias,
      horarios,
      isAdmin: !userComarcaId,
      disabledDate,
      vehiculos,
      idiomas,
      showOtrasTitulaciones,
      experiencia,
      comarcasTrabajoIds,
      comarcasSolicitaTrabajoIds,
      titulacion,
      titulacionOtras,
      disponibilidadesArray,
      documentos,
      documentosEliminar,
      documentosNuevos,
      estado,
      claseEstado,
      servicios,
      serviciosColectivos,
      isReading,
      erroresDocumentos,
      onSubmit: handleSubmit(async (values) => {
        guardaDetalle.value = true;
        try {
          await module
            .update({
              comarcaId: comarcaId,
              id: token as string,
              data: values,
            })
            .then(() => {
              if (!module.errors)
                ElNotification({
                  message: t(
                    "formularios.profesionalDetalle.guardar.mensaje.exito"
                  ),
                  type: "success",
                });
              router.replace("/listados/profesionales");
            });
        } catch (err) {
          ElNotification({
            message: t("formularios.profesionalesListado.guarda.error"),
            type: "error",
          });
        } finally {
          guardaDetalle.value = false;
        }
      }),
      eliminar: () => {
        Swal.fire({
          text: t("dialogs.eliminar.confirmacion"),
          icon: "question",
          showCancelButton: true,
          confirmButtonText: t("dialogs.okButton"),
          cancelButtonText: t("dialogs.cancelButton"),
          reverseButtons: true,
        }).then(({ isConfirmed }) => {
          if (!isConfirmed) return;

          cargaDetalle.value = true;
          return module
            .eliminar({
              comarcaId: (comarcaId ??
                datosProfesional.value?.comarcaId) as string,
              elementId: token as string,
            })
            .then(() => {
              router.replace("/listados/profesionales");
              ElMessage({
                message: t("dialogs.eliminar.exito"),
                type: "success",
              });
            })
            .catch((err) => {
              Swal.fire({
                text: t("dialogs.eliminar.error"),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: t("dialogs.okButton"),
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              console.error(err);
            })
            .finally(() => {
              cargaDetalle.value = false;
            });
        });
      },
      aceptarActualizacion: async () => {
        const data = {
          id: datosProfesionalActualizar.value?.id,
          datosAdjuntos: datosProfesionalActualizar.value?.datosAdjuntos,
          datosSolicitud: datosProfesionalActualizar.value?.datosSolicitud,
        };
        await module.aceptarDatosProfesional({
          comarcaId,
          id: token as string,
          data: data,
        });
        await module.fetchProfesionalCAById({ id: token as string });
        seSolicitaActualizacion.value = false;
        if (!module.errors) {
          Swal.fire({
            text: t("formularios.profesionalDetalle.aceptarDatos.exito"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      },
      rechazarActualizacion: async () => {
        const data = {
          id: datosProfesionalActualizar.value?.id,
          datosAdjuntos: datosProfesionalActualizar.value?.datosAdjuntos,
          datosSolicitud: datosProfesionalActualizar.value?.datosSolicitud,
        };
        await module.rechazarDatosProfesional({
          comarcaId,
          id: token as string,
          data: data,
        });
        seSolicitaActualizacion.value = false;
        if (!module.errors) {
          Swal.fire({
            text: t("formularios.profesionalDetalle.rechazarDatos.exito"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      },
      seSolicitaActualizacion,
    };
  },
  mounted() {
    const { t } = useI18n();
    const dropzoneNuevosDocumentos = new Dropzone(
      "#kt_dropzonejs_nuevos_documentos",
      {
        url: process.env.VUE_APP_API_URL,
        // The name that will be used to transfer the file
        paramName: "documento",
        maxFilesize: 10, // MB
        addRemoveLinks: true,
        uploadMultiple: true,
        autoProcessQueue: false,
        acceptedFiles: "image/*,application/pdf",
      }
    );
    dropzoneNuevosDocumentos.on("addedfile", async (file) => {
      this.isReading = true;
      try {
        const fileToAdd = file.type.includes("image/")
          ? await this.compressImage(file)
          : file;

        await this.includeDocumentoNuevo(fileToAdd);
        ElNotification({
          message: t("formularios.profesionalDetalle.guarda.terminado"),
          type: "info",
        });
      } catch (err) {
        Swal.fire({
          text: t("formularios.profesionalDetalle.guarda.errorArchivo"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        this.erroresDocumentos[file.name] = true;
        this.isReading = true;
      } finally {
        if (Object.keys(this.erroresDocumentos).length === 0) {
          this.isReading = false;
        } else {
          this.isReading = true;
        }
      }
    });
    dropzoneNuevosDocumentos.on("removedfile", (file) => {
      this.deleteDocumentoNuevo(file);
      delete this.erroresDocumentos[file.name];

      if (Object.keys(this.erroresDocumentos).length === 0) {
        this.isReading = false;
      }
    });
  },
});
